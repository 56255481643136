import { All_List_Keys_Admin_Website_CMS_GET, All_List_Keys_Admin_Website_CMS_Post } from 'api/ApiConst'
import React, { useCallback, useEffect, useState } from 'react'
import AdminCmsAddEdit from './AdminCmsAddEdit'
import { callGetApi, callPostApi } from 'api/ApiCaller'
import { Modal } from "react-bootstrap";


const AdminCmsMaster = ({show, hideModal}) => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);
    const [list, setList] = useState([]);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    useEffect(() => {
        getList();
    }, []);

    const getList = () => {
        callGetApi( All_List_Keys_Admin_Website_CMS_GET, {}, (response) => {
            setList(response.responsePacket);
        });
    };

    const handleEdit = (row) => {
        setRecordID(row);
        setShowAddUpdateModal(true);
    };

    return (
        <>

    <Modal show={show} onHide={() => hideModal()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>CMS</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#f4f5f8" }}>
            <div className="card" style={{minHeight: "490px"}}>
                <div className="card-body tableView row cms-bg">
                    {list.map((data) => (
                        <div className="col-lg-6" key={data}>
                            <div className="card" style={{ cursor: "pointer"}} onClick={() => handleEdit(data)}>
                                <div className="card-body" >
                                    <h5 className="mb-0">{data}</h5>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {showAddUpdateModal && <AdminCmsAddEdit show={showAddUpdateModal} hideModal={() => setShowAddUpdateModal(false)} recordID={recordID} handleRefresh={handleRefresh} />}

        </Modal.Body>
    </Modal>
    </>
    )
}

export default AdminCmsMaster