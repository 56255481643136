export const HTTPS = process.env.REACT_APP_HTTPS;
export const HOST = process.env.REACT_APP_HOST;
export const PORT = process.env.REACT_APP_PORT;
export const SERVICE = process.env.REACT_APP_SERVICE;
export const CLIENT_ID = "##CID##";

export const BASE_URL = `${HTTPS}://${HOST}:${PORT}` + (SERVICE !== "" ? "/" + SERVICE : "");
export const BASE_URL_AUTH = BASE_URL + "/auth/";
export const BASE_URL_V1 = BASE_URL + "/v1/";

const recordID = "{recordID}";
const activateURL = "activate/" + recordID;
const inActivateURL = "inactivate/" + recordID;
// const publish = "publish/" + recordID;
// const unpublish = "unPublish/" + recordID;
 const deleteURL = "delete/" + recordID;
 const reviveURL = "revive/" + recordID;
 const deletedListURL = "deleted-list";
// const listURL = "list";
const updateUrl = "update/" + recordID;
// const login = "login/"

// Login
export const CLIENT_LOGIN = BASE_URL_AUTH + `${CLIENT_ID}/login/`;

//Stock 
export const STOCK_BASE_URL = BASE_URL_V1 + `stock/${CLIENT_ID}/`;
export const STOCK_LIST_POST = STOCK_BASE_URL + "list";
export const REFRESH_STOCK_LIST_POST = STOCK_BASE_URL + "refreshStockList";
export const DeActive_Stock_Put = STOCK_BASE_URL + inActivateURL ;
export const Active_Stock_Put = STOCK_BASE_URL + activateURL;
export const Delete_Stock_Put = STOCK_BASE_URL + deleteURL;
export const Deleted_Stock_List_Post = STOCK_BASE_URL + deletedListURL ;
export const Revive_Stock_Put = STOCK_BASE_URL + reviveURL;


//Contest
export const CONTEST_BASE_URL = BASE_URL_V1 + `stock-contest/${CLIENT_ID}/`;
export const CONTEST_LIST_POST = CONTEST_BASE_URL + "list";
export const Delete_Contest_Put = CONTEST_BASE_URL + deleteURL;
export const Active_Contest_Put = CONTEST_BASE_URL + activateURL;
export const DeActive_Contest_Put = CONTEST_BASE_URL + inActivateURL;
export const ADD_CONTEST_POST = CONTEST_BASE_URL + "save";
export const GET_STOCK_CONTEST_GET = CONTEST_BASE_URL + "get/" + recordID;
export const Deleted_Contest_List_Post = CONTEST_BASE_URL + deletedListURL ;
export const Update_Contest_Put = CONTEST_BASE_URL + updateUrl ;
export const Revive_Contest_Put = CONTEST_BASE_URL + reviveURL;
export const StockContest_Cancel_Put = CONTEST_BASE_URL + "cancel/" + recordID;
export const StockContest_HistoryContestList_Post = CONTEST_BASE_URL + "historyContestList";
export const StockContest_AllHistoryDetails_GET = CONTEST_BASE_URL + "allHistoryDetails/" + recordID;

// Common APIs
export const Upload_File_POST_Base_64 = `${BASE_URL_V1}/uploadFile/inBase64`;
export const Upload_File_POST_MultiPart = `${BASE_URL_V1}/uploadFile/inMultipartFile`;


// Admin CMS APIs
export const All_List_Keys_Admin_Website_CMS_GET  = BASE_URL_V1 + `stock/website-cms/${CLIENT_ID}/list-keys`;
export const Details_Admin_Website_CMS_Get  = BASE_URL_V1 + `stock/website-cms/${CLIENT_ID}/` + recordID;
export const Update_Admin_Website_CMS_Put  = BASE_URL_V1 + `stock/website-cms/${CLIENT_ID}/`+ recordID;

//Admin Setting APIs
export const All_Project_Setting_List_POST  = BASE_URL_V1 + `stock/setting/${CLIENT_ID}/key-value-list`;
export const Update_Project_Setting_Put = BASE_URL_V1 + `stock/setting/${CLIENT_ID}/` + recordID;
export const Details_Project_Setting_Get = BASE_URL_V1 + `stock/setting/${CLIENT_ID}/` + recordID;
export const Send_To_Mail_Setting_Post = BASE_URL_V1 + `stock/setting/${CLIENT_ID}/sendCurrentPriceOnMail`;


