import { Component } from 'react';
import { format } from 'date-fns';

class DateUtilities extends Component {
    formattedDate = (timeStamp, resultFormat) => {
        if (timeStamp && timeStamp !== undefined) {
            return format(new Date(timeStamp), resultFormat);
        }
        return;
    }
    getTimestamp = (dateString) => {
        const [hours, minutes] = dateString?.split(':').map(Number);
        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return Math.floor(date.getTime() / 1000) * 1000;
    };
    formatTimeStamp = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    };
}

const dateUtilities = new DateUtilities()
export default dateUtilities;