import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { callGetApi, callPutApi } from 'api/ApiCaller';
import { Details_Admin_Website_CMS_Get, Update_Admin_Website_CMS_Put } from 'api/ApiConst';
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';

const AdminCmsAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const [record, setRecord] = useState("");

    useEffect(() => {
        if (recordID) {
            callGetApi(Details_Admin_Website_CMS_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                setRecord(response.responsePacket);
            });
        }
    }, [recordID]);

    const handleSaveCMS = () => {
        try {
            let data = {
                websiteCmsValue: record,
            };
            let url = Update_Admin_Website_CMS_Put.replaceAll("{recordID}", recordID);
            callPutApi(url, data, {}, (response) => {
                toast.success("CMS updated successfully!", { id: "toast" });
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };

    return (
        <Modal show={show} onHide={() => hideModal()} handleSubmit={handleSaveCMS}>
             <Modal.Header closeButton>
      </Modal.Header>
            <div className="form-group col-lg-12">
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: {
                            items: ["heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "mediaEmbed", "undo", "redo"],
                        },
                    }}
                    data={record}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setRecord(data);
                    }}
                    id="cms-editor-111111"
                />
            </div>
            <Modal.Footer>

                <Button variant="primary" onClick={handleSaveCMS}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AdminCmsAddEdit