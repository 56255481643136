import { LOGINTOKEN, deleteDataFromStore } from "utils/CookiesUitls";
import Contestmaster from "./contest/Contestmaster";
import { useCallback, useEffect, useState } from "react";

const Dashboard = () => {
  const [refreshFlag, setRefreshFlag] = useState(0);

  const handleLogout = () => {
    deleteDataFromStore(LOGINTOKEN);
    handleRefresh();
  };

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  return (
    <>
      <div className="col-lg-6">
        <Contestmaster />
      </div>
      {/* <div className="col-lg-6">
        <button className="btn btn-primary mt-2" onClick={handleLogout}>
          Logout
        </button>       
      </div> */}
    </>
  );
};

export default Dashboard;
