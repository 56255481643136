import { toast } from "react-hot-toast";
import dateUtilities from "./DateUtilities";

export const isEmpty = (val) => {
    if (!val || val === null || val === undefined || val === '' || val === 0) {
        return true;
    }
    return false;
};
export const isNumeric = (str) => {
    if (typeof str != 'string') {
        return false; // we only process strings!
    }
    return (
        !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
};
export const getImageSrc = (imgSrc) => {
    if (this.isEmpty(imgSrc)) {
        // if imgSrc is empty then set default image.
        return '/assets/images/001/user.png';
    }
    return imgSrc;
};
export const isMobile = (mobile) => {
    const regEx = /^[6-9]\d{9}$/;
    return regEx.test(mobile);
};
export const isEmail = (email) => {
    const regEx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regEx.test(email);
};
export const isUpiId = (upiId) => {
    const regEx = /[a-zA-Z0-9.\\-_]{2,256}@[a-zA-Z]{2,64}/;
    return regEx.test(upiId);
};

export const getDOB = value => {
    if (typeof value === "number" || value.toString().split("") === 1) {
        return dateUtilities.formattedDate(value, "yyyy-MM-dd")
    }
    return value
}

export const getTimeFromDate = value => {
    return new Date(value).getTime()
}

export const isValidIsdCode = (isdCode) => {
    const regEx = /^\+\d{1,3}$/;
    return regEx.test(isdCode);
}

export const isValidJsonString = (jsonString) => {
    try {
        JSON.parse(jsonString);
    } catch (e) {
        return false;
    }
    return true;
}

export const timeStampToDateTime = (timestamp) => {

const date = new Date(timestamp);

const year = date.getFullYear();
const month = date.getMonth() + 1; // Month is zero-based, so we add 1
const day = date.getDate();
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();

//const formattedDateTime = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day} ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
const formattedDateTime = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

return formattedDateTime



}