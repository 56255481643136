import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { isEmpty, timeStampToDateTime } from "utils/TextUtils";
import toast from "react-hot-toast";
import { callGetApi, callPostApi, callPutApi } from "api/ApiCaller";
import {
  ADD_CONTEST_POST,
  GET_STOCK_CONTEST_GET,
  Update_Contest_Put,
} from "api/ApiConst";
import Select from "react-select";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Addcontest = ({ show, hideModal, recordId, handleRefresh }) => {
  const _rewardDistributionRankWise = {
    rankFrom: 0,
    rankTo: 0,
    rewardPercentage: 0,
  };

  const prizePoolType = [
    { label: "GuaranteedPool", value: "GuaranteedPool" },
    { label: "PlayerPool", value: "PlayerPool" },
  ];

  const defaultObj = {
    title: "",
    description: "",
    publishDateTimeStamp: 0,
    startDateTimeStamp: 0,
    rewardDistributionList: [{ ..._rewardDistributionRankWise }],
    minimumNumberOfParticipantsRequired: 0,
    maximumNumberOfParticipants: 0,
    prizePoolTypeEnum: prizePoolType[0],
    maximumStocks: 0,
    minimumStocks: 0,
    endDateTimeStamp: 0,
    prizePool: 0,
    entryFee: 0,
    adminCommission: 0,
    usableBonusPercentage: 0,
    gameRule: "",
  };

  const [record, setRecord] = useState({ ...defaultObj });

  useEffect(() => {
    if (recordId) {
      callGetApi(
        GET_STOCK_CONTEST_GET.replaceAll("{recordID}", recordId),
        {},
        (response) => {
          if (response.errorCode === 0) {
            const res = response.responsePacket;
            res.startDateTimeStamp = timeStampToDateTime(
              res.startDateTimeStamp
            );
            res.publishDateTimeStamp = timeStampToDateTime(
              res.publishDateTimeStamp
            );
            res.endDateTimeStamp = timeStampToDateTime(res.endDateTimeStamp);
            res.prizePoolTypeEnum = prizePoolType.find(
              (item) => item.label === res.prizePoolTypeEnum
            );
            setRecord(res);
          }
        }
      );
    }
  }, [recordId]);

  // const calculateEntryFee = () => {
  //   const prizePool = record.prizePool;
  //   const adminCommission = record.adminCommission
  //   const adminCommission = record.adminCommission / 100;
  //   const maximumParticipants = record.maximumNumberOfParticipants;

  //   if (prizePool && adminCommission && maximumParticipants) {
  //     const amount = prizePool + (prizePool * adminCommission);
  //     const entryFee = Math.round(amount / maximumParticipants);
  //     setRecord((prev) => ({...prev, entryFee }));
  //   }
  // };

  const calculateEntryFee = () => {
    const prizePool = record.prizePool;
    const maximumParticipants = record.maximumNumberOfParticipants;

    if (prizePool && maximumParticipants) {
      const entryFee = Math.round(prizePool / maximumParticipants);
      setRecord((prev) => ({ ...prev, entryFee }));
    }
  };

  useEffect(() => {
    calculateEntryFee();
  }, [record.prizePool, record.maximumNumberOfParticipants]);

  const addRewardDistribution = () => {
    setRecord((prev) => ({
      ...prev,
      rewardDistributionList: [
        ...record.rewardDistributionList,
        { ..._rewardDistributionRankWise },
      ],
    }));
  };

  const removeRewardDistribution = (indexToRemove) => {
    if (record.rewardDistributionList.length > 1) {
      setRecord((prev) => ({
        ...prev,
        rewardDistributionList: prev.rewardDistributionList.filter(
          (item, index) => index !== indexToRemove
        ),
      }));
    }
  };

  const handleTextChange = (e) => {
    const { id, type, value } = e.target;

    if (type === "number") {
      setRecord((pre) => ({ ...pre, [id]: parseInt(value) }));
      return;
    }

    setRecord((pre) => ({ ...pre, [id]: value }));
  };

  const handleSelectChange = (e) => {
    setRecord((pre) => ({ ...pre, prizePoolTypeEnum: e, prizePool: 0 }));
  };

  const handlePrizeDistribution = (e, i) => {
    const { name, value } = e.target;
    const _rewardDistributionList = record.rewardDistributionList;
    _rewardDistributionList[i][name] = parseInt(value);

    setRecord((prev) => ({
      ...prev,
      rewardDistributionList: _rewardDistributionList,
    }));
  };

  const checkInputValidation = (record) => {

    if (isEmpty(record.title)) {
      toast.error("Title must not be empty.");
      return false;
    } else if (isEmpty(record.description)) {
      toast.error("Description must not be empty.");
      return false;
    } else if (isEmpty(record.publishDateTimeStamp)) {
      toast.error("Publish Date Time must not be empty.");
      return false;
    } else if (isEmpty(record.startDateTimeStamp)) {
      toast.error("Start Date Time must not be empty.");
      return false;
    } else if (isEmpty(record.minimumNumberOfParticipantsRequired)) {
      toast.error("Minimum Participants must not be empty.");
      return false;
    } else if (isEmpty(record.maximumNumberOfParticipants)) {
      toast.error("Maximum Participants must not be empty.");
      return false;
    } else if (isEmpty(record.endDateTimeStamp)) {
      toast.error("End Date Time must not be empty.");
      return false;
    } else if (isEmpty(record.maximumStocks)) {
      toast.error("Max Stocks must not be empty.");
      return false;
    } else if (isEmpty(record.minimumStocks)) {
      toast.error("Min Stocks must not be empty.");
      return false;
    } else if (record.publishDateTimeStamp > record.startDateTimeStamp) {
      toast.error(
        "Conest Start Date Time Must be grater than Publish Date Time."
      );
      return false;
    } else if (record.startDateTimeStamp > record.endDateTimeStamp) {
      toast.error(
        " Contest End Date Time Must be grater than Start Date Time."
      );
      return false;
    } else if (record.maximumStocks < record.minimumStocks) {
      toast.error(" Max Stocks Must be grater than Min Stocks.");
      return false;
    } else if (
      record.maximumNumberOfParticipants <
      record.minimumNumberOfParticipantsRequired
    ) {
      toast.error(
        "Maximum Participants Stocks Must be grater than Minimum Participants."
      );
      return false;
    }

    return true;
  };

  const checkrewardDistributionValidation = (rewardDistributionData, adminCommission) => {
    let _totalRewardPercentage = 0;
    let _rankTo = "";

    for (let i = 0; i < rewardDistributionData.length; i++) {
      const data = rewardDistributionData[i];
      if (isEmpty(data.rankFrom)) {
        toast.error("Rank From must not be empty.", { id: "error" });
        return false;
      } else if (isEmpty(data.rankTo)) {
        toast.error("Rank To must not be empty.", { id: "error" });
        return false;
      } else if (data.rankFrom > data.rankTo) {
        toast.error("Rank To must be greater than or equal to Rank From", { id: "error" });
        return false;
      } else if (isEmpty(data.rewardPercentage)) {
        toast.error("Reward Percentage must not be empty.", { id: "error" });
        return false;
      } else if (data.rankFrom <= _rankTo) {
        toast.error("Rank From must be greater than Previous Rank To.", { id: "error" });
        return false;
      } else if (data.rankTo > record.maximumNumberOfParticipants) {
        toast.error("Can't distribute reward more than maximum participants.", { id: "error" });
        return false;
      } else {
        _rankTo = data.rankTo;
        _totalRewardPercentage += parseInt(data.rewardPercentage);
      }
    }

    const targetPercentage = 100 - parseInt(adminCommission);

    if (_totalRewardPercentage !== targetPercentage) {
      toast.error(`Total reward percentage must be equal to ${targetPercentage}.`, { id: "error" });
      return false;
    }

    return true;
  };


  const handleSubmit = () => {
    if (!checkInputValidation(record)) {
      return;
    }

    if (!checkrewardDistributionValidation(record.rewardDistributionList, record.adminCommission)) {
      return;
    }

    const _record = { ...record };

    _record.publishDateTimeStamp = Math.floor(
      new Date(_record.publishDateTimeStamp).getTime()
    );
    _record.startDateTimeStamp = Math.floor(
      new Date(_record.startDateTimeStamp).getTime()
    );
    _record.endDateTimeStamp = Math.floor(
      new Date(_record.endDateTimeStamp).getTime()
    );
    _record.prizePoolTypeEnum = _record.prizePoolTypeEnum.value;



    if (!recordId || recordId === "") {
      callPostApi(
        ADD_CONTEST_POST,
        _record,
        {},
        (response) => {
          if (response.errorCode === 0) {
            toast.success(response.message);
            hideModal();
            handleRefresh();
          } else {
            toast.error(response.message);
          }
        },
        (e) => {
          toast.error(e.message);
        }
      );
    } else {
      callPutApi(
        Update_Contest_Put.replaceAll("{recordID}", recordId),
        _record,
        {},
        (response) => {
          if (response.errorCode === 0) {
            toast.success(response.message);
            hideModal();
            handleRefresh();
          } else {
            toast.error(response.message);
          }
        }
      );
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => hideModal()} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>{recordId ? "Update" : "Add New"} Contest</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#f4f5f8" }}>
          <>
            <div className="d-flex row">
              <div className="col-lg-12">
                <div className="form-group row align-items-center">
                  <div className="col-lg-4 my-2 mt-1">
                    <label>Title *</label>
                    <input
                      type="text"
                      className="form-control input-default"
                      id="title"
                      value={record.title}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-4 my-2 mt-1">
                    <label>Description *</label>
                    <input
                      type="text"
                      className="form-control input-default"
                      id="description"
                      value={record.description}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-4 my-2 mt-1">
                    <label>Prize Pool *</label>
                    <Select
                      options={prizePoolType}
                      value={record.prizePoolTypeEnum}
                      onChange={(e) => handleSelectChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-4 my-2 mt-0">
                    <label>Publish Date Time *</label>
                    <input
                      type="datetime-local"
                      className="form-control input-default"
                      id="publishDateTimeStamp"
                      value={record.publishDateTimeStamp}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-4 my-2 mt-1">
                    <label>Start Date Time*</label>
                    <input
                      type="datetime-local"
                      className="form-control input-default"
                      id="startDateTimeStamp"
                      value={record.startDateTimeStamp}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-4 my-2 mt-1">
                    <label>End Date Time*</label>
                    <input
                      type="datetime-local"
                      className="form-control input-default"
                      id="endDateTimeStamp"
                      value={record.endDateTimeStamp}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Minimum Participants *</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="minimumNumberOfParticipantsRequired"
                      value={record.minimumNumberOfParticipantsRequired}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Maximum Participants *</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="maximumNumberOfParticipants"
                      value={record.maximumNumberOfParticipants}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Min Stocks *</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="minimumStocks"
                      value={record.minimumStocks}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Max Stocks *</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="maximumStocks"
                      value={record.maximumStocks}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>
                <div className="form-group row align-items-center">

                  <div className="col-lg-3 my-2 mt-1">
                    <label>Admin Commission*</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="adminCommission"
                      min={0}
                      value={record.adminCommission}
                      onChange={handleTextChange}
                    />
                  </div>
                  {/* {record.prizePoolTypeEnum.value === "GuaranteedPool" && ( */}
                    <div className="col-lg-3 my-2 mt-1">
                      <label>PrizePool *</label>
                      <input
                        type="number"
                        className="form-control input-default"
                        id="prizePool"
                        value={record.prizePool}
                        onChange={handleTextChange}
                      />
                    </div>
                  {/* )} */}
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Entry Fee *</label>
                    <input
                      type="number"
                      disabled
                      className="form-control input-default"
                      id="entryFee"
                      value={record.entryFee}
                      onChange={handleTextChange}
                    />
                  </div>
                  <div className="col-lg-3 my-2 mt-1">
                    <label>Usable Bonus Percentage *</label>
                    <input
                      type="number"
                      className="form-control input-default"
                      id="usableBonusPercentage"
                      value={record.usableBonusPercentage}
                      onChange={handleTextChange}
                    />
                  </div>
                </div>
                <div className="card p-2 mb-2 ">
                  <h4>Reward Distribution</h4>
                  <hr></hr>
                  <div>
                    {record.rewardDistributionList.length !== 0 &&
                      record.rewardDistributionList.map((data, i) => (
                        <div
                          className="form-group gap-3  d-flex justify-content-between  align-items-center mb-3"
                          key={i}
                        >
                          <div className="w-100">
                            <label>Rank From *</label>
                            <input
                              type="number"
                              className="form-control input-default"
                              id="rankFrom"
                              name="rankFrom"
                              value={data.rankFrom}
                              onChange={(e) => handlePrizeDistribution(e, i)}
                            />
                          </div>
                          <div className="w-100">
                            <label>Rank To *</label>
                            <input
                              type="number"
                              className="form-control input-default"
                              id="rankTo"
                              name="rankTo"
                              value={data.rankTo}
                              onChange={(e) => handlePrizeDistribution(e, i)}
                            />
                          </div>
                          <div className="w-100">
                            <label>rewardPercentage *</label>
                            <input
                              type="number"
                              className="form-control input-default"
                              id="rewardPercentage"
                              name="rewardPercentage"
                              value={data.rewardPercentage}
                              onChange={(e) => handlePrizeDistribution(e, i)}
                            />
                          </div>
                          <div className="">
                            <span
                              className="btn btn-danger mt-4 "
                              onClick={() => removeRewardDistribution(i)}
                            >
                              Remove
                            </span>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="text-end">
                    <span
                      className="btn btn-primary "
                      onClick={() => addRewardDistribution()}
                    >
                      Add More
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-12 my-2 mt-1 '>
              <label>Game Rules</label>
              <CKEditor
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: ["numberedList",],
                  },
                }}
                data={record?.gameRule ? record.gameRule : ''}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setRecord(prevRecord => ({ ...prevRecord, gameRule: data }));
                }}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Addcontest;
