import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { Details_Project_Setting_Get, Update_Project_Setting_Put } from "api/ApiConst";
import { callGetApi, callPutApi } from "api/ApiCaller";
import { isEmpty} from "utils/TextUtils";
import { Button, Modal } from "react-bootstrap";


const ProjectSettingAddEdit = ({ show, hideModal, recordID, handleRefresh }) => {
    const [record, setRecord] = useState({
        settingValue: ""
    });

    useEffect(() => {
        if (recordID) {
            callGetApi(Details_Project_Setting_Get.replaceAll("{recordID}", recordID), {}, (response) => {
                const res = response.responsePacket;
                let recordObj = {
                    settingValue: res
                }
                setRecord(recordObj);
            });
        }
    }, [recordID]);

    const handleTextChange = (e) => {
        const { id, value } = e.target;
        setRecord(pre => ({ ...pre, [id]: value }))
    }

    const handleSave = () => {
        try {
            if (isEmpty(record.settingValue)) {
                toast.error("Setting Value must not be empty.", { id: "toast" })
                return;
            }
            let url = Update_Project_Setting_Put.replaceAll("{recordID}", recordID);
            callPutApi(url, record, {}, (response) => {
                toast.success("setting value updated successfully!", { id: "toast" });
                hideModal();
                handleRefresh();
            });
        } catch (error) {
            return;
        }
    };

    return (
        <Modal show={show} onHide={() => hideModal()} backdrop="static" header={`Update ${recordID} Details`} size="ms" handleSubmit={handleSave}>
              <Modal.Header closeButton>
              </Modal.Header>
            <Modal.Body>
                <div className="form-group col-lg-12">
                    <label>Setting Value *</label>
                    <input type="text" className="form-control input-default" id="settingValue" onChange={handleTextChange} value={record.settingValue} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSave}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ProjectSettingAddEdit;
