import React, { useCallback, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap"; // Add this import
import { All_Project_Setting_List_POST, Send_To_Mail_Setting_Post } from "api/ApiConst";
import PaginationDataTable from "../common/PaginationDataTable";
import ProjectSettingAddEdit from "./SettingAddEdit";
import { callPostApi } from "api/ApiCaller";
import toast from "react-hot-toast";

const ProjectSettingMaster = ({ show, hideModal }) => {
    const [refreshFlag, setRefreshFlag] = useState(0);
    const [recordID, setRecordID] = useState(null);
    const [showAddUpdateModal, setShowAddUpdateModal] = useState(false);

    const handleRefresh = useCallback(() => {
        setRefreshFlag(refreshFlag + 1);
    }, [refreshFlag]);

    const columns = useMemo(
        () => [
            {
                id: "settingKey",
                name: "Setting Key",
                selector: (row) => row?.key,
                sortable: false,
            },
            {
                id: "Setting Value",
                name: "Setting Value",
                selector: (row) => row?.value,
                sortable: true,
            },
            {
                id: "action",
                name: "",
                selector: (row) => {
                    return <div className="custom-column-action">{getActionButtons(row)}</div>;
                },
                width: "80px",
                sortable: false,
            },
        ],
        [handleRefresh]
    );

    const getActionButtons = (row) => {
        return (
            <div className="d-flex align-items-center justify-content-between">
                <Button className="listBtn me-2" onClick={() => handleEdit(row)}>
                    <i className="bx bx-pencil"></i>
                </Button>
            </div>
        );
    };

    const handleEdit = (row) => {
        setRecordID(row?.key);
        setShowAddUpdateModal(true);
    };

    const handleMail = () => {
        callPostApi(Send_To_Mail_Setting_Post, {}, {}, (response) => {
            if (response.errorCode === 0) {
                toast.success(response.message);
                handleRefresh();
            } else {
                toast.error(response.message);
            }
        },
            (e) => {
                toast.error(e.message);
            }
        );
    }


    return (
        <>
            <Modal show={show} onHide={() => hideModal()} size="xl">
                <Modal.Header closeButton>
                    <div className="d-flex justify-content-between w-100">
                        <Modal.Title>SETTINGS</Modal.Title>
                        <Button onClick={handleMail}>Send Mail</Button>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ background: "#f4f5f8" }}>
                    <div className="card">
                        <div className="card-body tableView">
                            <PaginationDataTable
                                paginationUrl={All_Project_Setting_List_POST}
                                serverSidePagination={false}
                                search={false}
                                pagination={false}
                                columns={columns}
                            />
                        </div>
                    </div>
                    {showAddUpdateModal && (
                        <ProjectSettingAddEdit
                            show={showAddUpdateModal}
                            hideModal={() => setShowAddUpdateModal(false)}
                            recordID={recordID}
                            handleRefresh={handleRefresh}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ProjectSettingMaster;