import React, { useState, useEffect, memo } from "react";

import DataTable from "react-data-table-component";
import { callPostApi } from "../../api/ApiCaller";

const PaginationDataTable = (props) => {
    // npm i react-data-table-component
    // npm i styled-components

    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        var jsonRequest = {
            pageNumber: currentPage - 1,
            pageSize: perPage,
            search: filterText,
        };

        if (props.filter && props.filter !== undefined && props.filter !== "") {
            jsonRequest = { ...jsonRequest, ...props.filter };
        }
        callPostApi(
            props.paginationUrl,
            jsonRequest,
            {},
            (response) => {
                if (props.serverSidePagination) {
                    setData(response.responsePacket.data);
                    setTotalRows(response.responsePacket.totalItems);
                } else {
                    const res = response.responsePacket;
                    if (props.filter) {
                        const filteredItems = res.filter(item => item.title && item.title.toLowerCase().includes(filterText.toLowerCase()));
                        setData(filteredItems);
                    } else {
                        setData(res);
                    }
                    setTotalRows(response.responsePacket.length);
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }, [props.paginationUrl, props.serverSidePagination, props.columns, perPage, currentPage, filterText]);

    const handleChange = (e) => {
        setCurrentPage(1);
        setFilterText(e.target.value);
    };
    const handleClear = () => {
        setFilterText("");
    };
    const subHeaderComponentMemo = () => {
        if (!props.search) {
            return <></>;
        }
        return (
            <>
                <div className="input-group mb-3" style={{ width: "auto" }}>
                    <input type="text" className="form-control" style={{ paddingLeft: "35px" }} placeholder="Search . . ." value={filterText} onChange={handleChange} />
                    <div className="input-group-prepend" style={{ position: "absolute", height: "100%", zIndex: "4" }}>
                        <span
                            className="input-group-text"
                            style={{
                                padding: "0px",
                                height: "100%",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        >
                            <i className="fa fa-search"></i>
                        </span>
                    </div>
                    <div className="input-group-append" onClick={handleClear}>
                        <span
                            className="input-group-text"
                            style={{
                                padding: "0px",
                                height: "100%",
                                width: "40px",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                            }}
                        >
                            <i className="fa fa-times"></i>
                        </span>
                    </div>
                </div>
            </>
        );
    };

    const customStyles = {
        headCells: {
            style: {
                color: "#9f9f9f", // override the cell padding for head cells
                fontWeight: "600",
            },
        },
    };

    return props.serverSidePagination ? (
        <DataTable
            className="custom-data-table"
            title=""
            subHeader={props.search}
            subHeaderComponent={props.search && subHeaderComponentMemo()}
            columns={props.columns}
            data={data}
            // progressPending={loading}
            // progressComponent={<Loader />}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={(e) => setPerPage(e)}
            onChangePage={(e) => setCurrentPage(e)}
            customStyles={customStyles}
        /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
        />
    ) : (
        <DataTable
            className="custom-data-table"
            title=""
            columns={props.columns}
            data={data}
            pagination={props.pagination}
            subHeader={props.search}
            subHeaderComponent={props.search && subHeaderComponentMemo()}
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            paginationResetDefaultPage={resetPaginationToggle}
        // progressPending={loading}
        // progressComponent={<Loader />}
        /* selectableRows onSelectedRowsChange={({ selectedRows }) => console.log(selectedRows)} */
        />
    );
};

export default memo(PaginationDataTable);