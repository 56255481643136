import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

import { callDeleteApi, callPostApi, callPutApi } from "../api/ApiCaller";
import { isEmpty } from "./TextUtils";
import { Upload_File_POST_Base_64, Upload_File_POST_MultiPart } from "../api/ApiConst";

//Action Active
export const handleActive = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};

//  Action Inactive
export const handleInactive = (url, refreshTable) => {

    console.log(url)
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};

//   Action Delete
export const handleDelete = (url, refreshTable) => {
    confirmAlert({
        title: "Delete ?",
        message: "Do you want to delete this record ?",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callDeleteApi(url, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};

// Action Revive
export const handleRevive = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handlePublish = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handleUnPublish = (url, refreshTable) => {
    confirmAlert({
        title: "Un-Publish ?",
        message: "Do you want to Un-Publish this record ?",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callPutApi(url, {}, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};

//   Action Cancel
export const handleCancel = (url, refreshTable) => {
    confirmAlert({
      title: "Alert ?",
      message: "Do you want to cancel this record ?",
      icon: "warning",
      overlayClassName: "overlay-custom-class-name",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            callPutApi( url, {}, {}, (response) => {
              if (response.errorCode === 0) {
                toast.success(response.message);
                refreshTable();
              } else {
                toast.error(response.message);
              }
            });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

// Start Revive
export const handleStart = (url, refreshTable) => {
    callPutApi(url, {}, {}, (response) => {
        if (response.errorCode === 0) {
            toast.success(response.message);
            refreshTable();
        } else {
            toast.error(response.message);
        }
    });
};


//   Action Publish
export const handleStop = (url, refreshTable) => {
    confirmAlert({
        title: "Do you want to Stop game ?",
        message: "",
        icon: "warning",
        buttons: [
            {
                label: "Yes",
                onClick: () => {
                    callPutApi(url, {}, {}, (response) => {
                        if (response.errorCode === 0) {
                            toast.success(response.message);
                            refreshTable();
                        } else {
                            toast.error(response.message);
                        }
                    });
                },
            },
            {
                label: "No",
            },
        ],
    });
};

export const callImageUploader = async (base64, fileName) => {
    const res = await fetch(base64);
    const blob = await res.blob();
    let formData = new FormData();
    formData.append("file", blob, fileName);
    return callImageUploaderApi(formData);
};

const callImageUploaderApi = async (formData) => {
    return new Promise((resolve, reject) => {
        callPostApi(
            Upload_File_POST_MultiPart,
            formData,
            {},
            (response) => {
                if (response.errorCode === 0) {
                    resolve(response.responsePacket);
                    return response.responsePacket;
                } else {
                    reject(null);
                    toast.error(response.message);
                }
            },
            (error) => {
                reject(null);
                toast.error(error);
                return null;
            }
        );
    });
};
