import React, { useCallback, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  StockContest_AllHistoryDetails_GET,
  StockContest_HistoryContestList_Post,
} from "../../api/ApiConst";
import PaginationDataTable from "../common/PaginationDataTable";

import dateUtilities from "utils/DateUtilities";
import { callGetApi } from "api/ApiCaller";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ContestHistory = ({ show, hideModal }) => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [showViewModal, setShowViewModal] = useState(false);
  const [record, setRecord] = useState(null);
  const [openAccordions, setOpenAccordions] = useState({});

  const handleAccordionToggle = (index) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const getHistoryDetails = (row) => {
    setShowViewModal(true);
    setRecord(row);
    callGetApi(StockContest_AllHistoryDetails_GET.replaceAll("{recordID}", row.uuid), {}, (response) => {
      const res = response.responsePacket;
      setRecord(res);
    });
  };

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => (
          <div
            className={row.active ? "led-green" : "led-red"}
            title={row.active ? "Active" : "Inactive"}
          />
        ),
        width: "60px",
        sortable: false,
      },
      {
        id: "title",
        name: "Title",
        selector: (row) => row?.title,
        sortable: false,
        width: "140px"
      },
      {
        id: "publishDateTimeStamp",
        name: "Publish Time",
        selector: (row) => dateUtilities.formattedDate(row?.publishDateTimeStamp, "dd-MM-yyyy, hh:mm aa"),
        sortable: false,
        wrap: true,
        width: "200px"
      },
      {
        id: "startDateTimeStamp",
        name: "Start Time",
        selector: (row) => dateUtilities.formattedDate(row?.startDateTimeStamp, "dd-MM-yyyy, hh:mm aa"),
        sortable: false,
        wrap: true,
        width: "200px"
      },
      {
        id: "entryFee",
        name: "Entry Fee",
        selector: (row) => row.entryFee,
        sortable: false,
        wrap: true,
        width: "100px"
      },
      {
        id: "prizePoolTypeEnum",
        name: "Prize Pool Type",
        selector: (row) => row.prizePoolTypeEnum,
        sortable: false,
        wrap: true,
        width: "180px"
      },
      {
        id: "prizePool",
        name: "Prize Pool",
        selector: (row) => row.prizePool,
        sortable: false,
        wrap: true,
        width: "120px"
      },
      {
        id: "viewDetails",
        name: "",
        selector: (row) => (
          <td onClick={() => getHistoryDetails(row)} style={{ cursor: 'pointer' }}>
            <button className="btn btn-primary btn-sm">View</button>
          </td>
        ),
        sortable: false,
        width: "100px"
      },
    ],
    [handleRefresh]
  );

  return (
    <>
      <Modal show={show} onHide={() => hideModal()} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Contest History List</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ background: "#f4f5f8" }}>
          <PaginationDataTable
            paginationUrl={StockContest_HistoryContestList_Post}
            serverSidePagination={true}
            search={true}
            columns={columns}
          />
        </Modal.Body>
      </Modal>

      {showViewModal && record && (
        <Modal show={showViewModal} onHide={() => setShowViewModal(false)} size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Contest History Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered ">
              <tbody>
                <tr>
                  <td className="contest-history-css"><strong>Title:</strong></td>
                  <td>{record.title}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Description:</strong></td>
                  <td>{record.description}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Publish Date:</strong></td>
                  <td>{dateUtilities.formattedDate(record.publishDateTimeStamp, "dd-MM-yyyy, hh:mm aa")}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Start Date:</strong></td>
                  <td>{dateUtilities.formattedDate(record.startDateTimeStamp, "dd-MM-yyyy, hh:mm aa")}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>End Date:</strong></td>
                  <td>{dateUtilities.formattedDate(record.endDateTimeStamp, "dd-MM-yyyy, hh:mm aa")}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Entry Fee:</strong></td>
                  <td>{record.entryFee}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Prize Pool:</strong></td>
                  <td>{record.prizePool}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Prize Pool Type:</strong></td>
                  <td>{record.prizePoolTypeEnum}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Minimum Participants:</strong></td>
                  <td>{record.minimumNumberOfParticipantsRequired}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Maximum Participants:</strong></td>
                  <td>{record.maximumNumberOfParticipants}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Minimum Stocks:</strong></td>
                  <td>{record.minimumStocks}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Maximum Stocks:</strong></td>
                  <td>{record.maximumStocks}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Admin Commission:</strong></td>
                  <td>{record.adminCommission}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Remaining Reward Amount:</strong></td>
                  <td>{record.remainingRewardAmount}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Usable Bonus Percentage:</strong></td>
                  <td>{record.usableBonusPercentage}</td>
                </tr>
                <tr>
                  <td className="contest-history-css"><strong>Contest Status:</strong></td>
                  <td>{record.contestStatus}</td>
                </tr>
              </tbody>
            </table>

            <h5 className="mt-4 fw-bold" style={{ color: "#000" }}>Reward Distribution</h5>
            <div className="bg-white rounded-2 p-3" style={{ boxShadow: "rgb(0 0 0 / 4%) 0px 0px 11px 2px" }}>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Rank From</th>
                    <th>Rank To</th>
                    <th>Reward Percentage</th>
                  </tr>
                </thead>
                <tbody>
                  {record?.rewardDistributionList?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.rankFrom}</td>
                      <td>{item.rankTo}</td>
                      <td>{item.rewardPercentage}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <h5 className="mt-4 fw-bold" style={{ color: "#000" }}>Stock Contest Portfolio List</h5>
            <div className="bg-white rounded-2 p-3 table-responsive" style={{ boxShadow: "rgb(0 0 0 / 4%) 0px 0px 11px 2px" }}>
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Player Name</th>
                    <th>Ranking</th>
                    <th>Total Score</th>
                    <th>Total Earnings</th>
                    <th>Coins Spend</th>
                    <th>Alpha Stock</th>
                    <th>Beta Stock</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {record?.stockContestPortfolioList?.map((item, index) => (
                    <React.Fragment key={index}>
                      <tr onClick={() => handleAccordionToggle(index)} style={{ cursor: "pointer" }}>
                        <td>{item.title}</td>
                        <td>{item.playerName}</td>
                        <td>{item.ranking}</td>
                        <td>{item.totalScore.toFixed(2)}</td>
                        <td>{item.totalEarnings.toFixed(2)}</td>
                        <td>{item.coinsSpend}</td>
                        <td>{item.alphaStockInstrumentToken}</td>
                        <td>{item.betaStockInstrumentToken}</td>
                        <td>
                          {openAccordions[index] ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
                        </td>
                      </tr>
                      {openAccordions[index] && (
                        <tr>
                          <td colSpan="9">
                            <div className="bg-white rounded-2 p-3 table-responsive" style={{ boxShadow: "rgb(0 0 0 / 4%) 0px 0px 11px 2px" }}>
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th>Name</th>
                                    <th>Buy/Sell</th>
                                    <th>Instrument Token</th>
                                    <th>Trading Symbol</th>
                                    <th>Coins Spend</th>
                                    <th>Units</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {item.playerStocks.map((stock, stockIndex) => (
                                    <tr key={stockIndex}>
                                      <td>{stock.name}</td>
                                      <td>{stock.buySell}</td>
                                      <td>{stock.instrumentToken}</td>
                                      <td>{stock.tradingSymbol}</td>
                                      <td>{stock.coinsSpend}</td>
                                      <td>{stock.unit.toFixed(2)}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>

                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ContestHistory;
