import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  callPatchApi,
  callPostApi,
  callPostApiWithoutAuth,
} from "../../api/ApiCaller";
import {
  STOCK_LIST_POST,
  DeActive_Stock_Put,
  Active_Stock_Put,
  Delete_Stock_Put,
  REFRESH_STOCK_LIST_POST,
  Deleted_Stock_List_Post,
  Revive_Stock_Put,
} from "../../api/ApiConst";
import PaginationDataTable from "../common/PaginationDataTable";
import {
  handleActive,
  handleInactive,
  handleDelete,
  handleRevive,
} from "../../utils/UserUtils";
import toast from "react-hot-toast";

const Stockmaster = ({ show, hideModal }) => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [viewDeletedList, setViewDeletedList] = useState(false);

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  //Active Inactive record
  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(
        DeActive_Stock_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    } else {
      handleActive(
        Active_Stock_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(
      Delete_Stock_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  //Revive Record
  const handleReviveData = (row) => {
    handleRevive(
      Revive_Stock_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  const handleRefreshStockList = () => {
    callPostApi(
      REFRESH_STOCK_LIST_POST,
      {},
      {},
      (response) => {
        if (response.errorCode === 0) {
          toast.success(response.message);
          handleRefresh();
        } else {
          toast.error(response.message);
        }
      },
      (e) => {
        toast.error(e.message);
      }
    );
  };

  const columns = useMemo(
    () => [
      {
        id: "status",
        name: "",
        selector: (row) => (
          <div
            className={row.active ? "led-green" : "led-red"}
            title={row.active ? "Active" : "Inactive"}
          />
        ),
        width: "60px",
        sortable: false,
      },

      {
        id: "exchange",
        name: "Exchange",
        selector: (row) => row?.exchange,
        sortable: false,
        // center: true,
        // width: "100px"
      },
      {
        id: "name",
        name: "Name",
        selector: (row) => row?.name,
        sortable: false,
        // center: true,
        // width: "100px"
      },
      {
        id: "tradingSymbol",
        name: "Trading Symbol",
        selector: (row) => row?.tradingSymbol,
        sortable: false,
        // center: true,
        // width: "100px"
      },
      {
        id: "action",
        name: "Action",
        selector: (row) => getActionButtons(row),
        width: "80px",
        sortable: false,
      },
    ],
    [handleRefresh]
  );

  //Action Buttons
  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="dropdown">
          <button
            className="btn btn-primary btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="bx bx-chevron-down"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={() => hideModal()} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Stock</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ background: "#f4f5f8" }}>
        <>
          <div className="asset-delete-btn d-flex gap-3">
            <label
              className="btn btn-primary 
            "
              onClick={() => handleRefreshStockList()}
            >
              Refresh
            </label>
            <label
              className="btn btn-primary 
          "
            >
              <input
                type="checkbox"
                className="form-check-input me-2"
                onChange={handleToggleDeletedView}
              />
              Deleted List
            </label>
          </div>
          <div className="stock-table">
            <PaginationDataTable
              paginationUrl={
                viewDeletedList ? Deleted_Stock_List_Post : STOCK_LIST_POST
              }
              serverSidePagination={true}
              search={true}
              columns={columns}
            >
            </PaginationDataTable>
          </div>
        </>
      </Modal.Body>
    </Modal>
  );
};

export default Stockmaster;
