import {
  Active_Contest_Put,
  CONTEST_LIST_POST,
  DeActive_Contest_Put,
  Delete_Contest_Put,
  Deleted_Contest_List_Post,
  Revive_Contest_Put,
  StockContest_Cancel_Put,
  Stock_Cancel_Put,
} from "../../api/ApiConst";
import React, { useCallback, useEffect, useState } from "react";
import Addcontest from "./Addcontest";
import { Button } from "react-bootstrap";
import {
  handleActive,
  handleCancel,
  handleDelete,
  handleInactive,
  handleRevive,
} from "utils/UserUtils";
import Stockmaster from "Components/stock/Stockmaster";
import { callPostApi } from "api/ApiCaller";
import Select from "react-select";
import AdminCmsMaster from "Components/cms-master/AdminCmsMaster";
import ProjectSettingMaster from "Components/setting-master/SettingMaster";
import ContestHistory from "./ContestHistory";
import dateUtilities from "utils/DateUtilities";

const Contestmaster = () => {
  const filterContestType = [
    { label: "All", value: "All" },
    { label: "UpComing", value: "UpComing" },
    { label: "OnGoing", value: "OnGoing" },
  ];

  const _filterObj = {
    pageNumber: 0,
    pageSize: 10,
    contestType: filterContestType[0],
    startDateTimeStamp: "",
    endDateTimeStamp: "",
  };

  const [refreshFlag, setRefreshFlag] = useState(0);
  const [showAddContestModel, setShowAddContestModel] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [stockMasterModel, setStockMasterModel] = useState(false);
  const [historyModel, setHistoryModel] = useState(false)
  const [cmsMaseteModel, setCmsMaseteModel] = useState(false);
  const [settingModal, setSettingModal] = useState(false)
  const [contestMasterModel, setContestMasterModel] = useState(false);
  const [viewDeletedList, setViewDeletedList] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filterObj, setFilterObj] = useState({ ..._filterObj });

  useEffect(() => {
    if (!showAddContestModel) {
      setRecordId(null);
    }
  }, [showAddContestModel]);

  const handleRefresh = useCallback(() => {
    setRefreshFlag(refreshFlag + 1);
  }, [refreshFlag]);

  useEffect(() => {
    getContestList();
  }, [refreshFlag, viewDeletedList]);

  useEffect(() => {
    getContestList();
  }, [filterObj.contestType]);

  const handleLoadMoreRecordsList = () => {
    getContestList(true);
  };

  const handleToggleDeletedView = () => setViewDeletedList(!viewDeletedList);

  const getContestList = async (isLoadingMore = false) => {
    const obj = { ...filterObj };

    obj.contestType = obj.contestType.value;

    obj.startDateTimeStamp =
      obj.startDateTimeStamp === ""
        ? ""
        : Math.floor(new Date(obj.startDateTimeStamp).getTime());
    obj.endDateTimeStamp =
      obj.endDateTimeStamp === ""
        ? ""
        : Math.floor(new Date(obj.endDateTimeStamp).getTime());

    const rUrl = viewDeletedList
      ? Deleted_Contest_List_Post
      : CONTEST_LIST_POST;
    callPostApi(
      rUrl,
      {
        ...obj,
      },
      {},
      (response) => {
        if (response.errorCode === 0) {
          const res = response.responsePacket.data;
          if (isLoadingMore) {
            setRecordData([...recordData, ...res]);
            if (filterObj.pageNumber === 0) {
              setFilterObj((prev) => ({ ...prev, pageNumber: 1 }));
            } else {
              setFilterObj((prev) => ({
                ...prev,
                pageNumber: prev.pageNumber + 1,
              }));
            }
          } else {
            setRecordData(res);
          }
          setTotalRecords(response.responsePacket.totalItems);

        }
      }
    );
  };

  //  Edit template
  const handleEdit = (row) => {
    setRecordId(row.uuid);
    setShowAddContestModel(true);
  };

  //Active Inactive record
  const handleActiveInactive = (row) => {
    if (row.active) {
      handleInactive(
        DeActive_Contest_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    } else {
      handleActive(
        Active_Contest_Put.replaceAll("{recordID}", row.uuid),
        handleRefresh
      );
    }
  };

  //Delete Record
  const handleDeleteData = (row) => {
    handleDelete(
      Delete_Contest_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  const handleReviveData = (row) => {
    handleRevive(
      Revive_Contest_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  };

  const handleCancelRecord = (row) => {
    console.log(row);
    handleCancel(
      StockContest_Cancel_Put.replaceAll("{recordID}", row.uuid),
      handleRefresh
    );
  }

  const getActionButtons = (row) => {
    return (
      <div className="d-flex align-items-center justify-content-between">
        <div className="dropdown">
          <button
            className="btn mx-2 p-0 btn-flat btn-addon btn-xs dropdown-toggle"
            title="More"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{ padding: "5px 10px 8px 10px" }}
          >
            <i className="fa fa-ellipsis-v text-white" aria-hidden="true"></i>
          </button>
          <ul className="dropdown-menu dropdown-menu-right">
            <>
              {row.deleted ? (
                <li onClick={() => handleReviveData(row)}>
                  <i className="bx bx-log-out-circle"></i> Revive
                </li>
              ) : (
                <>
                  <li onClick={() => handleEdit(row)}>
                    <i className="bx bx-trash"></i> Edit
                  </li>
                  <li onClick={() => handleDeleteData(row)}>
                    <i className="bx bx-trash"></i> Delete
                  </li>
                  <li onClick={() => handleActiveInactive(row)}>
                    {row.active ? (
                      <>
                        <i className="bx bx-x-circle"></i> Inactive
                      </>
                    ) : (
                      <>
                        <i className="bx bx-check-circle"></i> Active
                      </>
                    )}
                  </li>
                  <li onClick={() => handleCancelRecord(row)}>
                    <i className="bx bx-x-circle"></i> Cancel
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    );
  };

  const handleTextChange = (e) => {
    const { id, value } = e.target;
    setFilterObj((prev) => ({ ...prev, [id]: value, pageNumber: 0 }));
  };

  const handleSelectChange = (e) => {
    setFilterObj((prev) => ({ ...prev, contestType: e, pageNumber: 0 }));
  };

  const handleSearchContest = () => {
    getContestList();
  };

  const clearSearchContest = () => {
    setFilterObj({ ..._filterObj });
  };

  return (
    <>
      <div className="row mt-2 ">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5 className="mb-0">STOCK FANTASY</h5>
          <div className="ml-auto">
            <Button className="me-2" onClick={() => setCmsMaseteModel(true)}>
              CMS
            </Button>
            <Button className="me-2" onClick={() => setSettingModal(true)}>
              Settings
            </Button>
            <Button className="me-2" onClick={() => setHistoryModel(true)}>
              History
            </Button>
            <Button className="me-2" onClick={() => setStockMasterModel(true)}>
              Stock
            </Button>
            <Button
              className="me-2"
              onClick={() => setShowAddContestModel(true)}
            >
              + Add Contest
            </Button>
            <label className="btn btn-primary mt-2">
              <input
                type="checkbox"
                className="form-check-input me-2"
                onChange={handleToggleDeletedView}
              />
              Deleted List
            </label>
          </div>
        </div>
        <hr></hr>
      </div>

      <div className="row mt-2 ">
        <div className="col-lg-3 my-2 mt-1">
          <Select
            options={filterContestType}
            value={filterObj.contestType}
            onChange={(e) => handleSelectChange(e)}
          />
        </div>
        <div className="col-lg-3 my-2 mt-1">
          <input
            type="datetime-local"
            className="form-control input-default"
            id="startDateTimeStamp"
            value={filterObj.startDateTimeStamp}
            onChange={handleTextChange}
          />
        </div>
        <div className="col-lg-3 my-2 mt-1">
          <input
            type="datetime-local"
            className="form-control input-default"
            id="endDateTimeStamp"
            value={filterObj.endDateTimeStamp}
            onChange={handleTextChange}
          />
        </div>
        <div className="col-lg-3 my-2 mt-1">
          <div className="d-flex gap-2">
            <Button className="w-100" variant="primary" onClick={handleSearchContest}>
              {" "}
              Search
            </Button>
            <Button variant="primary" className="me-2 w-100" onClick={clearSearchContest}>
              {" "}
              Refresh
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-3 ">
        <div className="col-12">
          <div className="card p-2">
            {recordData.length !== 0 &&
              recordData.map((data, i) => (
                <div className="card border bg-light " key={i}>
                  <div className="d-flex justify-content-between align-items-center bg-dark text-white py-1 rounded-top">
                    <div className="d-flex align-items-center">
                      <span
                        className={`${data.active ? "led-green" : "led-red"
                          } me-2`}
                      ></span>
                      <span>{data.title}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className=" bg-success px-2 rounded">
                        {data.contestStatus}
                      </span>
                      <div className="h3 mb-0 text-white cursor-pointer">
                        {getActionButtons(data)}
                      </div>
                    </div>
                  </div>
                  <div className="pt-2">
                    <table className="table mb-0 tamplatelist">
                      <tbody>
                        <tr>
                          <th scope="row">Entry Fee</th>
                          <td>
                            <span title="Bet Amount">
                              {data.entryFee} ₹
                            </span>
                          </td>
                          <th scope="row">Admin Commission</th>
                          <td>
                            <span title="Bet Amount">
                              {data.adminCommission.toFixed(2)}
                            </span>
                          </td>
                          <th scope="row">Min/Max Participants </th>
                          <td>
                            <span>{data.minimumNumberOfParticipantsRequired} /{data.maximumNumberOfParticipants} </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Minimum Stocks</th>
                          <td>
                            <span title="Bet Amount">{data.minimumStocks}</span>
                          </td>
                          <th scope="row">Maximum Stocks</th>
                          <td>
                            <span>{data.maximumStocks}</span>
                          </td>
                          <th scope="row">Usable Bonus Percentage</th>
                          <td>
                            <span>{data.usableBonusPercentage}</span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Start Date TimeStamp</th>
                          <td>
                            <span>{dateUtilities.formattedDate(data.startDateTimeStamp, "dd-MM-yyyy p")}</span>
                          </td>
                          <th scope="row">Publish Date TimeStamp</th>
                          <td>
                            <span>{dateUtilities.formattedDate(data.publishDateTimeStamp, "dd-MM-yyyy p")}</span>
                          </td>
                        </tr>
                        <tr>
                          <td scope="row" colSpan={3}>
                            <div className="d-flex">
                              <span className="bg-secondary text-white px-2 rounded">
                                {data.prizePoolTypeEnum}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            { }
            {recordData.length < totalRecords && (
              <Button onClick={handleLoadMoreRecordsList}>Load More</Button>
            )}
            {recordData.length === 0 && (
              <div>There are no records to display</div>
            )}
          </div>
        </div>
      </div>

      {showAddContestModel && (
        <Addcontest
          show={showAddContestModel}
          hideModal={() => setShowAddContestModel(false)}
          recordId={recordId}
          handleRefresh={handleRefresh}
        />
      )}

      {historyModel && (
        <ContestHistory
          show={historyModel}
          hideModal={() => setHistoryModel(false)}
        />
      )}

      {stockMasterModel && (
        <Stockmaster
          show={stockMasterModel}
          hideModal={() => setStockMasterModel(false)}
        />
      )}

      {cmsMaseteModel && (
        <AdminCmsMaster
          show={cmsMaseteModel}
          hideModal={() => setCmsMaseteModel(false)}
        />
      )}

      {settingModal && (
        <ProjectSettingMaster
          show={settingModal}
          hideModal={() => setSettingModal(false)}
        />
      )}

      {contestMasterModel && (
        <Contestmaster
          show={contestMasterModel}
          hideModal={() => setContestMasterModel(false)}
        />
      )}
    </>
  );
};

export default Contestmaster;
